import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Tip } from '../../Help';
import { Link, useHistory } from '../../../router/utils';
import Table from '../../Table';
import { InputContext } from '../context';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    addVendor: {
        textAlign: 'right',
    },
}));

const columns = [
    { label: 'Name', id: 'displayName' },
    { label: 'Subscribable', id: 'isSubscribable', type: 'boolean' },
    { label: 'InstantCancellation', id: 'isInstant', type: 'boolean' },
    { label: 'Negotiatable', id: 'isNegotiable', type: 'boolean' },
    { label: 'Deleted', id: 'deleted', type: 'boolean' },
];

export default ({ claims, vendors, getAll }: any = {}) => {
    const input = useContext(InputContext);
    const classes = useStyles();
    React.useEffect(() => {
        getAll();
    }, [getAll]);

    const canCreateVendors = claims.act.includes('cv');
    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Search Vendors</title>
            </Helmet>
            <Grid container spacing={3} className={classes.root}>
                <Grid item sm={10} md={8}>
                    {!vendors && <CircularProgress />}
                    {vendors && (
                        <Table
                            title="Vendor List"
                            columns={columns}
                            rows={vendors}
                            // pageSize={25}
                            onRowClick={(evt: any, selectedRow: any) => {
                                history.push(`/dashboard/vendors/${selectedRow.vendorId}`);
                            }}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            defaultFilter={input.input}
                        />
                    )}
                </Grid>
                {canCreateVendors && (
                    <Grid item sm={2} md={1}>
                        <Link to="/dashboard/vendors/new">
                            <Button variant="contained">
                                <AddIcon />
                            </Button>
                        </Link>
                    </Grid>
                )}
                <Grid item sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to search for vendors by name.
                        </Typography>
                        <Typography variant="body2" component="p">
                            Click on a row to view that vendor.
                        </Typography>
                    </Tip>
                </Grid>
            </Grid>
        </>
    );
};
